<template>
    <div class="subject">
        <div>
            <b-tabs pills v-model="tab" class="border-2">
                <b-tab title="مرحلة التعليم الجامعي" active> </b-tab>
                <b-tab title="مرحلة التعليم المدرسي"> </b-tab>
            </b-tabs>
        </div>
        <b-card no-body class="mb-1">
            <b-card-header class="pb-0">
                <strong class="mr-auto">فلترة حسب</strong>
                <b-button
                    type="submit"
                    class="mr-1"
                    variant="primary"
                    @click="
                        getchapterList({ ...filterDto, search: search.search })
                    "
                    >فلترة
                </b-button>
                <b-button variant="secondary" @click="resetFilter"
                    >تهيئة</b-button
                >
            </b-card-header>
            <b-card-body class="pb-50">
                <b-row>
                    <b-col cols="12" md="3">
                        <ek-input-select
                            label="جامعة"
                            placeholder=" اختر جامعة"
                            :options="[
                                { id: 0, name: 'الكل' },
                                ...universityList,
                            ]"
                            v-model="filterDto.universityId"
                            name="select"
                            :clearable="true"
                        />
                    </b-col>
                    <b-col cols="12" md="3">
                        <ek-input-select
                            label="الكلية"
                            placeholder="اختر الكلية "
                            :options="[{ id: 0, name: 'الكل' }, ...facultyList]"
                            name="select"
                            v-model="filterDto.facultyId"
                            :clearable="true"
                        />
                    </b-col>
                    <b-col cols="12" md="2">
                        <ek-input-select
                            label="السنة"
                            placeholder="اختر السنة "
                            :options="[{ id: 0, name: 'الكل' }, ...years]"
                            name="select"
                            v-model="filterDto.yearId"
                            :clearable="true"
                        />
                    </b-col>
                    <b-col cols="12" md="2">
                        <ek-input-select
                            label="الفصل"
                            placeholder="اختر الفصل "
                            :options="[{ id: 0, name: 'الكل' }, ...semesters]"
                            name="select"
                            v-model="filterDto.semesterId"
                            :clearable="true"
                        />
                    </b-col>
                    <b-col cols="12" md="2">
                        <ek-input-select
                            label="المادة"
                            placeholder="اختر المادة "
                            :options="[
                                { id: 0, name: 'الكل' },
                                ...subjectsNames,
                            ]"
                            name="select"
                            v-model="filterDto.subjectId"
                            :clearable="true"
                        />
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>

        <ek-table
            :columns="tab == 0 ? columns : newColumns"
            :items="chapterList"
            @delete-selected="deleteChapter"
            @details="detailsChapter"
        >
            <template slot="items.dateCreated" slot-scope="{ value }">
                <span> {{ new Date(value).toLocaleDateString() }}</span>
            </template>
        </ek-table>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
    data() {
        return {
            tab: 0,
            filterDto: {
                semesterId: "",
                yearId: "",
                facultyId: "",
                universityId: "",
                subjectId: "",
                studentType: this.tab,
            },
        };
    },

    computed: {
        ...mapState({
            chapterList: (state) => state.chapter.chapterList.chapters,
            columns: (state) => state.chapter.columns,
            newColumns: (state) => state.chapter.newColumns,
            universityList: (state) => state.university.universityList,
            facultyList: (state) => state.faculty.facultyList,
            branches: (state) => state.branches.branchList,
            years: (state) => state.settings.yearList,
            semesters: (state) => state.settings.semesterList,
            search: (state) => state.chapter.filterDto,
            subjectsNames: ({ subjects }) => subjects.subjectsNames,
        }),
        // ...mapGetters(["subjectsList"]),
    },
    methods: {
        paginate(e) {
            console.log(e);
            this.getchapterList({
                ...this.filterDto,
                pageIndex: e,
                studentType: this.tab,
            });
        },
        goToDetails(id) {
            this.$router.push({ path: `/admin/chapter/${id}` });
        },
        ...mapActions([
            "getchapterList",
            "getFacultyList",
            "getUniversityList",
            "getSettingYear",
            "getSettingSemester",
            "getGetUnRead",
            "getBranchList",
            "getSubjectsNames",
            "getUnitList",
        ]),

        resetFilter() {
            Object.assign(this.filterDto, {
                semesterId: "",
                yearId: "",
                facultyId: "",
                universityId: "",
                subjectId: "",
            });
            this.getchapterList({ ...this.filterDto, studentType: this.tab });
        },
        deleteChapter(e) {
            this.$store.dispatch("deletechapter", e).finally(() => {
                this.getchapterList({
                    ...this.filterDto,
                    studentType: this.tab,
                });
            });
        },
        detailsChapter(id) {
            console.log("fdf", id);
            this.$router.push(`/admin/chapter/${id.row.id}`);
        },
    },
    created() {
        this.getchapterList({ ...this.filterDto, studentType: 0 });
        this.getFacultyList(0);
        this.getUniversityList(0);
        this.getBranchList(0);
        this.getSubjectsNames(0);
        this.getUnitList({ studentType: 0 });
        this.getSettingYear();
        this.getSettingSemester();
        this.getGetUnRead();
    },

    watch: {
        tab: function (newTab) {
            if (newTab == 0) {
                console.log(newTab, "dfsdfsdfsdf");
                this.getchapterList({
                    ...this.filterDto,
                    studentType: 0,
                });
                this.getFacultyList(0);
                this.getUnitList({ studentType: 0 });
                this.getUniversityList(0);
                this.getNamesUniversity(0);
                this.getBranchList(0);
                this.getSubjectsNames(0);
            } else {
                this.getchapterList({
                    ...this.filterDto,
                    studentType: 1,
                });
                this.getFacultyList(1);
                this.getUniversityList(1);
                this.getSubjectsNames(1);

                this.getBranchList(1);
                this.getUnitList({
                    studentType: 1,
                });

                this.getNamesUniversity(1);
            }
        },
    },
};
</script>

<style lang="scss">
@import "/src/@core/scss/core.scss";

.subject {
    #my-card {
        position: relative;
    }
    p {
        margin: 5px;
    }
    .badge {
        position: absolute;
        top: 10px;
        right: 9px;
    }
    img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 10px;
    }

    .unicon.button {
        svg {
            fill: $main-color !important;
        }
    }

    svg {
        fill: #6e6b7b;
    }

    .subjects {
        .card-body {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }

        .card-header {
            padding: 0 !important;
        }

        .card-footer {
            padding: 0 !important;
            text-align: center;
            cursor: pointer;

            button {
                color: #fff !important;
                border-top-left-radius: 0 !important;
                border-top-right-radius: 0 !important;
            }
        }
    }
}
</style>
